export const memoryFoamFeatures = [
  {
    image: "/images/OurMaterials/MemoryFoam/soft.svg",
    alt: "Soft",
    featureTitle: "Soft",
    featureDescription:
      "Feel the mattresses take your shape as soon as you get on it. Oh and that “memory” part? it’s because it remembers.",
  },
  {
    image: "/images/OurMaterials/MemoryFoam/high-quality.svg",
    alt: "High Quality",
    featureTitle: "High Quality",
    featureDescription:
      "Memory foam is built to last thanks to its flexibility and resilience.",
  },
  {
    image: "/images/OurMaterials/MemoryFoam/comfy.svg",
    alt: "Comfy",
    featureTitle: "Comfy",
    featureDescription:
      "Naturally following the curves of your body, memory foam will remember how you sleep and adapt to your body.",
  },
  {
    image: "/images/OurMaterials/MemoryFoam/long-lasting.svg",
    alt: "Long Lasting",
    featureTitle: "Long Lasting",
    featureDescription:
      "Memory foam is durable and resistant, which will ensure that your mattresses and pillows stay with you for a very long time.",
  },
];

export const foamFeatures = [
  {
    image: "/images/OurMaterials/Foam/firm.svg",
    alt: "Firm",
    featureTitle: "Firm",
    featureDescription:
      "Foam is a firm material that provides extra support for your back and neck. We use it alone or in combination with other materials to reach different levels of fermness.",
  },
  {
    image: "/images/OurMaterials/Foam/healthy.svg",
    alt: "Healthy",
    featureTitle: "Healthy",
    featureDescription:
      "Preferred by hospitals and caring facilities, foam is a durable material that helps recovery and promotes a healthy night and helps recovery",
  },
  {
    image: "/images/OurMaterials/Foam/versatile.svg",
    alt: "Versatile",
    featureTitle: "Versatile",
    featureDescription:
      "Foam is everywhere, from your couch to your bed and that’s because it’s so comfortable, lasting, and versatile.",
  },
];

export const pocketedSpringsFeatures = [
  {
    image: "/images/OurMaterials/PocketedSprings/springs.svg",
    alt: "Independent Springs",
    featureTitle: "Independent Springs",
    featureDescription:
      "Pocketed spring mattresses uses a “sleeve” to encase each spring, making them independent from one another.",
  },
  {
    image: "/images/OurMaterials/PocketedSprings/affordable.svg",
    alt: "Affordable",
    featureTitle: "Affordable",
    featureDescription:
      "Pocketed springs are an affordable yet high-quality choice that will deliver on our promise of comfort nurturing sleep",
  },
  {
    image: "/images/OurMaterials/PocketedSprings/double-beds.svg",
    alt: "For Double Beds",
    featureTitle: "For Double Beds",
    featureDescription:
      "You can move around in your bed without affecting your partner, the motion will be isolated by the spring on which you lie so you can move around without affecting your partner, making them perfect for double beds.",
  },
];

export const springsFeatures = [
  {
    image: "/images/OurMaterials/Springs/bouncy.jpg",
    alt: "Bouncy",
    featureTitle: "Bouncy",
    featureDescription:
      "Spring mattresses are bouncy and provide with mellow comfort. We also combine them with different materials to reach different levels of firmness.",
  },
  {
    image: "/images/OurMaterials/Springs/affordable.jpg",
    alt: "Affordable",
    featureTitle: "Affordable",
    featureDescription:
      "Springs are of one the staples of mattress materials, making them affordable to everyone.",
  },
  {
    image: "/images/OurMaterials/Springs/breathing.svg",
    alt: "Breathing",
    featureTitle: "Breathing",
    featureDescription:
      "Creating airspace under you, spring mattresses let you breathe and keep cool during the night",
  },
];

export const naturalFeltFeatures = [
  {
    image: "/images/OurMaterials/NaturalFelt/sustainable.svg",
    alt: "Sustainable",
    featureTitle: "Sustainable",
    featureDescription:
      "Made from pure natural sheep’s wool it is environmentally friendly and recyclable after the mattress or pillow is discarded.",
  },
  {
    image: "/images/OurMaterials/NaturalFelt/firm.svg",
    alt: "Firm",
    featureTitle: "Firm",
    featureDescription:
      "Felt makes for very firm mattresses and pillows which for certain people means total comfort.",
  },
  {
    image: "/images/OurMaterials/NaturalFelt/hypoallergenic.svg",
    alt: "Hypoallergenic",
    featureTitle: "Hypoallergenic",
    featureDescription:
      "We use high-quality, temperature regulating wool that is completely hypoallergenic.",
  },
];

export const feathersAndDownFeatures = [
  {
    image: "/images/OurMaterials/FeathersAndDown/poofy.svg",
    alt: "Poofy",
    featureTitle: "« Poofy »",
    featureDescription:
      "Nothing beats the sensation of putting your head down on a pillow and feel it go “”poof””",
  },
  {
    image: "/images/OurMaterials/FeathersAndDown/temprature.svg",
    alt: "Temprature Control",
    featureTitle: "Temprature Control",
    featureDescription:
      "Down and feather naturally retain your heat when it’s cold and stay cooler than room temperature in the summer",
  },
  {
    image: "/images/OurMaterials/FeathersAndDown/long-lasting.svg",
    alt: "Long Lasting",
    featureTitle: "Long Lasting",
    featureDescription:
      "Such traditional materials combined with modern technology help us make down and feather stuff that lasts.",
  },
];
