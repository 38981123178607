import React from "react";
import PropTypes from "prop-types";
import styles from "./modules/ContactItem.module.scss";

function ContactItem({ iconSrc, title, children }) {
  return (
    <div className={styles.contactItem}>
      <img src={iconSrc} alt={title} className={styles.icon} loading="lazy" />
      <div className={styles.text}>
        <h3>{title}</h3>
        <p>{children}</p>
      </div>
    </div>
  );
}

ContactItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContactItem;
