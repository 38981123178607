import React from "react";
import { Link } from "react-router-dom";
import styles from "./modules/MobileFooter.module.scss";

function MobileFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.mobileFooter}>
      <div className={styles.logoContainer}>
        <img
          src="/images/mobile-footer-logo.svg"
          alt="NAM Logo"
          className={styles.logo}
          loading="lazy"
        />
      </div>
      <div className={styles.linksContainer}>
        <div className={styles.column}>
          <h4 className={styles.columnTitle}>NAM Products</h4>
          <ul>
            <li>
              <Link to="/">Home Page</Link>
            </li>
            <li>
              <Link to="/products">Our Products</Link>
            </li>
          </ul>
        </div>
        <div className={styles.column}>
          <h4 className={styles.columnTitle}>The World of NAM</h4>
          <ul>
            <li>
              <a href="/our-materials">Materials</a>
            </li>
            <li>
              <a href="/for-the-hospitality-business">
                For the hospitality business
              </a>
            </li>
            <li>
              <a href="/for-medical-uses">For medical uses</a>
            </li>
            <li>
              <a href="/for-special-care">For special care</a>
            </li>
          </ul>
        </div>
        <div className={styles.column}>
          <div
            className={styles.columnTitle}
            style={{ marginBottom: 27 }}
          ></div>
          <ul>
            <li>
              <a href="/about-nam">About NAM</a>
            </li>
            <li>
              <a href="/contact-us">Contact us</a>
            </li>
          </ul>
        </div>
        <div className={styles.footerMisc}>
          <p>NAM House of Sleep {currentYear}&copy;</p>
          <a href="#top" className={styles.backToTop}>
            <div className={styles.backToTopIconContainer}>
              <img
                src="/images/up-arrow.svg"
                alt="Back to top"
                className={styles.backToTopIcon}
                loading="lazy"
              />
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default MobileFooter;
