import React from "react";
import styles from "./modules/SpecialCare.module.scss"; // Import SCSS
import ContactUsBlock from "../../../molecules/ContactUs/ContactUsBlock"; // Import ContactUsBlock component

function SpecialCare() {
  return (
    <div className={styles.specialCarePage}>
      {/* First Block */}
      <div className={styles.firstBlock}>
        <div className={styles.content}>
          <h1>A solution to every need</h1>
          <p>
            Tell us what you need and let our sleep experts guide you to find
            the perfect fit. We can even tailor custom solutions just for you.
          </p>
        </div>
      </div>
      {/* Second Block */}
      <div className={styles.secondBlock}>
        {/* Left Image */}
        <div className={styles.leftImage}>
          <img
            src="/images/ForYourBusiness/SpecialCare/special-care.jpg"
            alt="Medical Equipment"
            loading="lazy"
          />
        </div>

        {/* Right Content */}
        <div className={styles.rightContent}>
          <h2>More than mattresses and pillows</h2>
          <div className={styles.mobileImage}>
            <img
              src="/images/ForYourBusiness/SpecialCare/special-care.jpg"
              alt="Medical Equipment"
              loading="lazy"
            />
          </div>
          <p>
            In addition to our beds and pillows, NAM can provide you with beds,
            sheets and bedding accessories that will transform your nights and
            answer any need you may have.
          </p>
        </div>
      </div>

      {/* Third Block */}
      <div className={styles.thirdBlock}>
        <h2>From home use to nursing homes and everyone in between</h2>
        <p>
          You may suffer from back or neck pain, you may be recovering from an
          injury or looking to increase the comfort of a parent. Whatever you
          may need, our sleep experts are here to guide you and to provide the
          right solution within your budget.
        </p>
        <button className={styles.collectionsButton}>Our collections</button>
      </div>

      {/* Fourth Block */}
      <div className={styles.fourthBlock}>
        <div className={styles.leftContent}>
          <h2>The unparalleled sleep quality of Biolatex</h2>
          <div className={styles.mobileImage}>
            <img
              src="/images/ForYourBusiness/SpecialCare/biolatex-mattress.jpg"
              alt="Biolatex"
              loading="lazy"
            />
          </div>
          <p>
            Whether your need is medical or not, your night will forever change
            once you enter the luxurious world of Biolatex mattress. We combine
            it with different materials to reach different levels of firmness,
            but the comfort is simply unprecedented.
          </p>
          <button className={styles.biolatexButton}>More on Biolatex</button>
        </div>

        <div className={styles.rightImage}>
          <img
            src="/images/ForYourBusiness/SpecialCare/biolatex-mattress.jpg"
            alt="Biolatex"
            loading="lazy"
          />
        </div>
      </div>

      <ContactUsBlock showBottomImage={false} showBackgroundImage={false} />
    </div>
  );
}

export default SpecialCare;
