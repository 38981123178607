import React from "react";
import PropTypes from "prop-types";
import styles from "./modules/FeatureBlock.module.scss"; // SCSS remains unchanged

function FeatureBlock({ title, description, features }) {
  return (
    <div className={styles.featureBlock}>
      <div className={styles.textContent}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>

      {/* Conditionally render the features section only if features are provided */}
      {features && features.length > 0 && (
        <div className={styles.features}>
          {features.map((feature, index) => (
            <div className={styles.feature} key={index}>
              <img src={feature.image} alt={feature.alt} loading="lazy" />
              <div className={styles.featureText}>
                <h3>{feature.featureTitle}</h3>
                <p>{feature.featureDescription}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

FeatureBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      featureTitle: PropTypes.string.isRequired,
      featureDescription: PropTypes.string.isRequired,
    })
  ),
};

FeatureBlock.defaultProps = {
  features: null, // Default to null when no features are passed
};

export default FeatureBlock;
