import React from "react";
import Header from "../organisms/Header/Header";
import Footer from "../organisms/Footer/Footer";
import MobileMenu from "../organisms/MobileMenu/MobileMenu";
import MobileFooter from "../organisms/MobileFooter/MobileFooter";

function Layout({ children }) {
  return (
    <div>
      {/* Desktop Header */}
      <Header />

      {/* Mobile Menu */}
      <MobileMenu />

      <main>{children}</main>

      {/* Desktop Footer */}
      <Footer />

      {/* Mobile Footer */}
      <MobileFooter />
    </div>
  );
}

export default Layout;
