import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./modules/MobileMenu.module.scss";

function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsSubmenuOpen(false); // Close submenu when the main menu is closed
    }
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.topBar}>
        <div className={styles.menuButton} onClick={toggleMenu}>
          <div className={isOpen ? styles.closeIcon : styles.burgerIcon}></div>
        </div>
        <div className={styles.logoContainer}>
          <Link to="/" onClick={toggleMenu}>
            <img
              src="/images/mobile-header-logo.svg"
              alt="Logo"
              className={styles.logo}
              loading="lazy"
            />
          </Link>
        </div>
      </div>
      {isOpen && (
        <nav className={styles.menuContent}>
          <ul>
            <li>
              <Link to="/products" onClick={toggleMenu}>
                NAM Products
              </Link>
            </li>
            <li>
              <div className={styles.submenu} onClick={toggleSubmenu}>
                <span>For Your Business</span>
                <span
                  className={isSubmenuOpen ? styles.arrowUp : styles.arrowDown}
                ></span>
              </div>
              {isSubmenuOpen && (
                <ul className={styles.submenuList}>
                  <li>
                    <Link
                      to="/for-the-hospitality-business"
                      onClick={toggleMenu}
                    >
                      For the Hospitality Business
                    </Link>
                  </li>
                  <li>
                    <Link to="/for-medical-uses" onClick={toggleMenu}>
                      For Medical Uses
                    </Link>
                  </li>
                  <li>
                    <Link to="/for-special-care" onClick={toggleMenu}>
                      For Special Care
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/our-materials" onClick={toggleMenu}>
                Our Materials
              </Link>
            </li>
            <li>
              <Link to="/about-nam" onClick={toggleMenu}>
                About NAM
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className={styles.contactButton}
                onClick={toggleMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default MobileMenu;
