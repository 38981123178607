import React from "react";
import PropTypes from "prop-types";
import ContactInfo from "./ContactInfo";
import styles from "./modules/ContactUsBlock.module.scss";

function ContactUsBlock({ showBottomImage, showBackgroundImage }) {
  return (
    <div
      className={`${styles.firstBlock} ${
        showBackgroundImage ? styles.withBackground : ""
      }`}
    >
      {/* Left side: Contact Info */}
      <ContactInfo />

      {/* Right side: Contact Form */}
      <div className={styles.contactForm}>
        <form>
          <div className={styles.formGroup}>
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" placeholder="Enter your name" />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="email">Your Email</label>
            <input type="email" id="email" placeholder="Enter your email" />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" placeholder="Enter subject" />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="message">Your Message</label>
            <textarea id="message" placeholder="Enter your message"></textarea>
          </div>

          <button className={styles.sendButton} type="submit">
            Send
          </button>
        </form>
      </div>

      {/* Conditionally render Bottom Image */}
      {showBottomImage && (
        <div className={styles.bottomImage}>
          <img
            src="/images/ContactUs/horizontal-ressort.svg"
            alt="Contact Us"
            loading="lazy"
          />
        </div>
      )}
    </div>
  );
}

ContactUsBlock.propTypes = {
  showBottomImage: PropTypes.bool,
  showBackgroundImage: PropTypes.bool,
};

ContactUsBlock.defaultProps = {
  showBottomImage: true, // Default to showing the bottom image
  showBackgroundImage: true, // Default to showing the background image
};

export default ContactUsBlock;
