import React from "react";
import styles from "./modules/AboutNAM.module.scss"; // Import SCSS for styling

function AboutNAM() {
  return (
    <div className={styles.aboutNAMPage}>
      {/* First Block */}
      <div className={styles.firstBlock}>
        <div className={styles.content}>
          <h1>Designing great nights for over 60 years</h1>
          <p>
            At NAM, comfortable nights is part of the family history. Since its
            creation in 1958, the Abou Khalil family has improved the nights of
            thousands of families and businesses.
          </p>
        </div>
      </div>

      {/* Second Block */}
      <div className={styles.secondBlock}>
        <div className={styles.leftSide}>
          <h2>
            1958 – 1990 <br />
            our humble beginnings
          </h2>
          <div className={styles.imageContainer}>
            <img
              src="/images/AboutNAM/about-beginings.jpg"
              alt="Our humble beginings"
              loading="lazy"
            />
          </div>
          <p>
            For its first 32 years of activity, NAM made mattresses the
            traditional way, weaving sheep wool in cotton casing. We provided
            home service and took a special place in the community for over
            three decades.
          </p>
        </div>

        {/* Thin vertical line */}
        <div className={styles.divider}></div>

        <div className={styles.rightSide}>
          <h2>
            1990 – today <br />
            the expansion
          </h2>
          <div className={styles.imageContainer}>
            <img
              src="/images/AboutNAM/about-expansion.jpg"
              alt="The expansion"
              loading="lazy"
            />
          </div>

          <p>
            In 1990 Georges Abou Khalil decided to expand so he gathered his 30
            years of experience sleep-crafting and started manufacturing
            mattresses in Sin El Fil. Since then, the Abou Khalil family have
            built their own manufacturing facility which uses premium quality
            materials to make Lebanon’s finest sleep attire. Today, NAM is
            synonymous with a great sleeping experience everywhere in Lebanon,
            from happy homes to hotels and hospitals.
          </p>
        </div>
      </div>

      {/* Third Block */}
      <div className={styles.thirdBlock}>
        <div className={styles.row}>
          {/* First Row, First Column */}
          <div className={styles.column}>
            <img
              src="/images/AboutNAM/Authenticity.svg"
              alt="Authenicity"
              className={styles.image}
              loading="lazy"
            />
            <h3>Authenticity</h3>
            <p>
              Made in Lebanon and coming from <br />
              decades of know-how.
            </p>
          </div>

          {/* First Row, Second Column */}
          <div className={styles.column}>
            <img
              src="/images/AboutNAM/Dedication.svg"
              alt="Dedication"
              className={styles.image}
              loading="lazy"
            />
            <h3>Dedication</h3>
            <p>
              We work hard to fulfill our customer’s <br />
              need to experience or provide a great <br />
              night of sleep.
            </p>
          </div>
        </div>

        <div className={styles.row}>
          {/* Second Row, First Column */}
          <div className={styles.column}>
            <img
              src="/images/AboutNAM/Counsel.svg"
              alt="Counsel"
              className={styles.image}
              loading="lazy"
            />
            <h3>Counsel</h3>
            <p>
              We help our customers to find the <br />
              right choice for their sleeping needs.
            </p>
          </div>

          {/* Second Row, Second Column */}
          <div className={styles.column}>
            <img
              src="/images/AboutNAM/Consistency.svg"
              alt="Consistency"
              className={styles.image}
              loading="lazy"
            />
            <h3>Consistency</h3>
            <p>
              We stand by our community in good <br />
              times and bad.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutNAM;
