// src/components/organisms/Header/Header.js
import React from "react";
import { Link } from "react-router-dom";
import styles from "./modules/Header.module.scss";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/contact-us");
  };
  return (
    <header className={styles.header}>
      <div className={styles.logoNavContainer}>
        <Link to="/" className={styles.logo}>
          <img
            src="/images/header-logo.svg"
            alt="Website Logo"
            loading="lazy"
          />
        </Link>

        <nav className={styles.navTabs}>
          <div className={styles.navTab}>
            <Link to="/products">NAM Products</Link>
          </div>

          <div className={styles.navTab}>
            <Link to="/business">For Your Business</Link>
            <ul className={styles.dropdown}>
              <li>
                <Link to="/for-the-hospitality-business">Hospitality</Link>
              </li>
              <li>
                <Link to="/for-medical-uses">Medical</Link>
              </li>
              <li>
                <Link to="/for-special-care">Special Care</Link>
              </li>
            </ul>
          </div>

          <div className={styles.navTab}>
            <Link to="/our-materials">Our Materials</Link>
          </div>
          <div className={styles.navTab}>
            <Link to="/about-nam">About NAM</Link>
          </div>
        </nav>
      </div>

      <div className={styles.searchContainer}>
        <div className={styles.searchIcon}>
          <img
            src="/images/magnifier.svg"
            className={styles.searchImage}
            alt="Search Icon"
            loading="lazy"
          />
        </div>
        <input
          type="text"
          placeholder="Search..."
          className={styles.searchField}
        />
      </div>

      <Button
        text="Contact us!"
        className={styles.contactButton}
        onClick={handleRedirect}
      />
    </header>
  );
}

export default Header;
