import React from "react";
import styles from "./modules/OurMaterials.module.scss"; // Assuming you're using SCSS for styling
import FeatureBlock from "../../molecules/FeatureBlock/FeatureBlock";
import {
  memoryFoamFeatures,
  foamFeatures,
  pocketedSpringsFeatures,
  springsFeatures,
  naturalFeltFeatures,
  feathersAndDownFeatures,
} from "../../../data/MaterialsData";

function OurMaterials() {
  return (
    <div className={styles.ourMaterialsPage}>
      {/* First Block */}
      <div className={styles.firstBlock}>
        <div className={styles.content}>
          <h1>Our materials, the secret to your comfort</h1>
          <p>
            We only produce and work with the best materials available to make
            sure that everyone who goes home with a NAM product always comes
            back satisfied.
          </p>
        </div>
      </div>

      {/* Second Block */}
      <div className={styles.secondBlock}>
        <h2>Biolatex, the absolute dream</h2>
        <p className={styles.description}>
          Durable, sustainable, natural, organic, hypoallergenic, ergonomic
        </p>

        <div className={styles.columns}>
          <div className={styles.column}>
            <img
              src="/images/OurMaterials/materials-organic.png"
              alt="organic"
              className={styles.columnImage}
              loading="lazy"
            />
            <h3>Organic</h3>
            <p className={styles.columnDescription}>
              Our Biolatex is pure, organic, high-quality latex grown and
              harvested all over the world, then brought to Lebanon and made
              into mattresses and pillows in our factory in Sin El Fil.
            </p>
          </div>

          <div className={styles.column}>
            <img
              src="/images/OurMaterials/materials-sustainable.png"
              alt="sustainable"
              className={styles.columnImage}
              loading="lazy"
            />
            <h3>Sustainable</h3>
            <p className={styles.columnDescription}>
              It a sustainable material, a guaranteed hypoallergenic that
              delivers a level of comfort you haven’t experienced elsewhere.
            </p>
          </div>

          <div className={styles.column}>
            <img
              src="/images/OurMaterials/materials-ergonomic.png"
              alt="ergonomic"
              className={styles.columnImage}
              loading="lazy"
            />
            <h3>Ergonomic</h3>
            <p className={styles.columnDescription}>
              Biolatex is a breathing material that brings a new level of
              ergonomy and comfort by adapting to your body. We have the
              mattresses and pillows to transform your nights and bring that
              exceptional sleeping experience right to your home.
            </p>
          </div>
        </div>
      </div>

      {/* Third Block using FeatureBlock */}
      <FeatureBlock
        title="Memory foam"
        description="Memory foam is a choice of excellence for luxurious nights."
        features={memoryFoamFeatures}
      />

      <FeatureBlock
        title="Foam"
        description="Firm and adapted to all kinds of sleeping needs, foam will be your best friend whether you’re recovering from back pain or just looking for improved comfort"
        features={foamFeatures}
      />

      <FeatureBlock
        title="Pocketed Springs"
        description="Pocketed springs are a staple of mattress making that provides high quality comfort and independence."
        features={pocketedSpringsFeatures}
      />

      <FeatureBlock
        title="Springs"
        description="As classic mattress material, we all know and love the spring mattress."
        features={springsFeatures}
      />

      <FeatureBlock
        title="Natural felt"
        description="Felt is the dense sheep wool used in traditional mattress, pillow, and comforters. It’s been used by NAM since 1958 and is still among our staples."
        features={naturalFeltFeatures}
      />

      <FeatureBlock
        title="Feathers and down"
        description="Our feather and down pillows are light andcan stay in your bed all year long as they help keep warm in the winter and keep cool in the summer."
        features={feathersAndDownFeatures}
      />

      <FeatureBlock
        title="Organic cotton, cotton & fibers"
        description="All of our sheets and casings are made from organic cotton, cotton, and/or fibers. These ensure the comfort and durability of our products, enhancing your night of sleep."
      />
    </div>
  );
}

export default OurMaterials;
