import React from "react";
import ContactUsBlock from "../../molecules/ContactUs/ContactUsBlock";
import styles from "./modules/ContactUs.module.scss";

function ContactUs() {
  return (
    <div className={styles.contactUsPage}>
      {/* First Block */}
      <ContactUsBlock showBottomImage={true} showBackgroundImage={true} />

      {/* Second Block: Map */}
      <div className={styles.mapBlock}>
        <iframe
          title="NAM Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1431.333074906115!2d35.540593817944085!3d33.86903704830279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17b0965dfde7%3A0x7aed95ecb97681ab!2sNAM!5e0!3m2!1sen!2str!4v1725617827515!5m2!1sen!2str"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactUs;
